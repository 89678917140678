// src/store.js
import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    conversionRates: {}
  },
  mutations: {
    SET_CONVERSION_RATES(state, payload) {
      console.log('Setting conversion rates:', payload);
      state.conversionRates = payload;
    }
  },
  actions: {
    fetchConversionRates({ commit }) {
      axios.get('/api/aggregate_daily_activities.php')
        .then(response => {
          console.log('Fetched conversion rates:', response.data);
          commit('SET_CONVERSION_RATES', response.data);
        })
        .catch(error => console.error('Error fetching conversion rates:', error));
    },
    updateDailyActivities({ dispatch }, activityData) {
      axios.post('/api/add_daily_activity.php', activityData)
        .then(() => {
          dispatch('fetchConversionRates'); // Re-fetch rates after updating activities
        })
        .catch(error => console.error('Error updating daily activities:', error));
    },
    async switchUser(context, userId) {
    try {
      const response = await axios.post('/api/switch-user', { userId });
      const token = response.data.token;
      sessionStorage.setItem('userSwitchToken', token);
      window.location.reload(); // Reloads the page
    } catch (error) {
      console.error('Failed to switch user:', error);
    }
  }
  }
});
