<template>
  <div class="user-management">
    <h2>User Management</h2>
    <div class="logout-link" @click="logout">Logout</div>
    <div class="management-sections">
      <div class="add-user">
        <h3>Add New User</h3>
        <form @submit.prevent="addUser">
          <div class="form-group">
            <label for="first_name">First Name:</label>
            <input type="text" id="first_name" v-model="newUser.first_name" required>
          </div>
          <div class="form-group">
            <label for="last_name">Last Name:</label>
            <input type="text" id="last_name" v-model="newUser.last_name" required>
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="newUser.email" required>
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" id="password" v-model="newUser.password" required>
          </div>
          <div class="form-group">
            <label for="start_date">Start Date:</label>
            <input type="date" id="start_date" v-model="newUser.start_date">
          </div>
          <button type="submit">Add User</button>
        </form>
      </div>
      <div class="password-reset">
        <h3>Reset User Password</h3>
        <form @submit.prevent="resetPassword">
          <div class="form-group">
            <label for="user">Select User:</label>
            <select v-model="selectedUserForPasswordReset">
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.first_name }} {{ user.last_name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="new_password">New Password:</label>
            <input type="password" id="new_password" v-model="newPassword" required>
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
    <div class="user-switcher">
      <h3>Switch User View</h3>
      <select v-model="selectedUser" @change="switchUser">
        <option v-for="user in users" :key="user.id" :value="user.id">
          {{ user.first_name }} {{ user.last_name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      newUser: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        start_date: ''
      },
      users: [],
      selectedUser: null,
      selectedUserForPasswordReset: null,
      newPassword: ''
    };
  },
  methods: {
    addUser() {
      axios.post('/api/add_user.php', this.newUser)
        .then(response => {
          if (response.data.status === 'success') {
            alert('User added successfully!');
            this.newUser = { first_name: '', last_name: '', email: '', password: '', start_date: '' }; // Reset form
            this.fetchUsers(); // Refresh the list of users
          } else {
            alert('Failed to add user: ' + response.data.message);
          }
        })
        .catch(error => {
          console.error('Error adding user:', error);
          alert('Error adding user');
        });
    },
    fetchUsers() {
      axios.get('/api/fetch_users.php')
        .then(response => {
          this.users = response.data.users;
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    },
    switchUser() {
      const params = new URLSearchParams();
      params.append('userId', this.selectedUser);

      axios.post('/api/switch_user.php', params)
        .then(response => {
          if (response.data.token) {
            console.log('User switched successfully, token:', response.data.token);
            window.location.reload(true);
          } else {
            alert('Error switching user: ' + response.data.error);
          }
        })
        .catch(error => {
          console.error('Error switching user:', error);
          alert('Error switching user');
        });
    },
    resetPassword() {
      axios.post('/api/reset_password.php', {
        userId: this.selectedUserForPasswordReset,
        newPassword: this.newPassword
      })
      .then(response => {
        if (response.data.status === 'success') {
          alert('Password reset successfully!');
          this.newPassword = ''; // Reset form
        } else {
          alert('Failed to reset password: ' + response.data.message);
        }
      })
      .catch(error => {
        console.error('Error resetting password:', error);
        alert('Error resetting password');
      });
    },
    logout() {
      axios.post('/api/logout.php')
        .then(response => {
          if (response.data.status === 'success') {
            alert('Logged out successfully!');
            window.location.reload(true);
          } else {
            alert('Error logging out: ' + response.data.message);
          }
        })
        .catch(error => {
          console.error('Error logging out:', error);
          alert('Error logging out');
        });
    }
  },
  mounted() {
    this.fetchUsers();
  }
};
</script>

<style scoped>
.user-management {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logout-link {
  float: right;
  color: #0056b3;
  cursor: pointer;
  text-decoration: underline;
  margin-top: -30px;
  margin-right: -30px;
}

.user-management h2, .user-management h3 {
  color: #333;
  text-align: center;
}

.management-sections {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.add-user, .password-reset {
  flex: 1;
  margin: 10px;
  background: #f8f8f8;
  padding: 20px;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-size: 1rem;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="date"]:focus {
  border-color: #0056b3;
  outline: none;
}

button {
  width: 100%;
  padding: 12px;
  background-color: #0056b3;
  color: white;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #004494;
}

.user-switcher {
  margin-top: 20px;
  background: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  color: #333;
  cursor: pointer;
}

select:focus {
  border-color: #0056b3;
}
</style>
