<template>
  <div class="conversion-rates">
    <h2>Conversion Rates</h2>
    <ul>
      <li>Dollar per Hour Lead Generating: ${{ dollarPerHourLeadGen }}</li>
      <li>Hours Lead Generated for 1 Intentional Conversation: {{ hoursPerIntentionalConversation }}</li>
      <li>Hours Lead Generated for 1 Viable Opportunity: {{ hoursPerViableOpportunity }}</li>
      <li>Intentional Conversations per Viable Opportunity: {{ intentionalConversationsPerViableOpportunity }}</li>
      <li>Viable Opportunities per Appointment: {{ viableOpportunitiesPerAppointment }}</li>
      <li>Appointments to Contracts Signed: {{ appointmentsToContractsSigned }}</li>
      <li>Contracts to Properties Under Contract: {{ contractsToPropertiesUnderContract }}</li>
      <li>Properties to Closings: {{ propertiesToClosings }}</li>
      <li>Average GCI per Closing: ${{ averageGCIPerClosing }}</li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ConversionRates',
  computed: {
    ...mapState(['conversionRates']),
    dollarPerHourLeadGen() {
      return this.conversionRates.totalHoursLeadGenerated > 0 ? (parseFloat(this.conversionRates.totalGCIEarned) / parseFloat(this.conversionRates.totalHoursLeadGenerated)).toFixed(2) : "0.00";
    },
    hoursPerIntentionalConversation() {
      return this.conversionRates.totalIntentionalConversations > 0 ? (parseFloat(this.conversionRates.totalHoursLeadGenerated) / parseInt(this.conversionRates.totalIntentionalConversations, 10)).toFixed(2) : "0.00";
    },
    hoursPerViableOpportunity() {
      return this.conversionRates.totalViableOpportunities > 0 ? (parseFloat(this.conversionRates.totalHoursLeadGenerated) / parseInt(this.conversionRates.totalViableOpportunities, 10)).toFixed(2) : "0.00";
    },
    intentionalConversationsPerViableOpportunity() {
      return this.conversionRates.totalViableOpportunities > 0 ? (parseInt(this.conversionRates.totalIntentionalConversations, 10) / parseInt(this.conversionRates.totalViableOpportunities, 10)).toFixed(2) : "0.00";
    },
    viableOpportunitiesPerAppointment() {
      return this.conversionRates.apptsPresented > 0 ? (parseInt(this.conversionRates.totalViableOpportunities, 10) / parseInt(this.conversionRates.apptsPresented, 10)).toFixed(2) : "0.00";
    },
    appointmentsToContractsSigned() {
      return this.conversionRates.apptsPresented > 0 && this.conversionRates.contractsSigned > 0 ? (parseInt(this.conversionRates.apptsPresented, 10) / parseInt(this.conversionRates.contractsSigned, 10)).toFixed(2) : "0.00";
    },
    contractsToPropertiesUnderContract() {
      return this.conversionRates.contractsSigned > 0 && this.conversionRates.propertiesUnderContract > 0 ? (parseInt(this.conversionRates.contractsSigned, 10) / parseInt(this.conversionRates.propertiesUnderContract, 10)).toFixed(2) : "0.00";
    },
    propertiesToClosings() {
      return this.conversionRates.propertiesUnderContract > 0 && this.conversionRates.closingsHad > 0 ? (parseInt(this.conversionRates.propertiesUnderContract, 10) / parseInt(this.conversionRates.closingsHad, 10)).toFixed(2) : "0.00";
    },
    averageGCIPerClosing() {
      return this.conversionRates.closingsHad > 0 ? (parseFloat(this.conversionRates.totalGCIEarned) / parseInt(this.conversionRates.closingsHad, 10)).toFixed(2) : "0.00";
    }
  },
  methods: {
    ...mapActions(['fetchConversionRates']),
  },
  mounted() {
    this.fetchConversionRates();
  }
};
</script>

<style scoped>
.conversion-rates {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.conversion-rates h2 {
  font-size: 1.2rem;
  color: #0056b3;
  margin-bottom: 15px;
}

.conversion-rates ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.conversion-rates li {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #333;
}

.conversion-rates li strong {
  color: #0056b3;
}

@media (max-width: 768px) {
  .conversion-rates {
    padding: 10px;
    margin-top: 10px;
  }

  .conversion-rates h2 {
    font-size: 1rem;
  }

  .conversion-rates li {
    font-size: 0.8rem;
  }
}
</style>
