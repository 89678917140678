<template>
  <div class="goals-panel">
    <h2>Goals Panel</h2>
    <form @submit.prevent="updateGoals">
      <div class="form-group">
        <label for="averageSalesPrice">Average Sales Price:</label>
        <input type="number" id="averageSalesPrice" v-model="metrics.averageSalesPrice">
      </div>
      <div class="form-group">
        <label for="averageCommissionRate">Average Commission Rate (%):</label>
        <input type="number" id="averageCommissionRate" v-model="metrics.averageCommissionRate" step="0.01">
      </div>
      <div class="form-group">
        <label for="goalForGCI">Goal for GCI:</label>
        <input type="number" id="goalForGCI" v-model="metrics.goalForGCI">
      </div>
      <!-- Display Calculated Values -->
      <div class="results">
        <p>Average Commission Per Transaction: ${{ formattedCommissionPerTransaction }}</p>
        <p>Number of Transactions Needed to Close: {{ formattedTransactionsNeeded }}</p>
        <p>Deals Needed in Pipeline: {{ dealsNeededInPipeline }}</p>
      </div>
      <button type="submit">Save Goals</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'GoalsPanel',
  data() {
    return {
      metrics: {
        averageSalesPrice: 0,
        averageCommissionRate: 0,
        goalForGCI: 0
      }
    };
  },
  computed: {
    formattedCommissionPerTransaction() {
      const result = this.metrics.averageSalesPrice * (this.metrics.averageCommissionRate / 100);
      return result.toFixed(2);
    },
    formattedTransactionsNeeded() {
      const commissionPerTransaction = parseFloat(this.formattedCommissionPerTransaction);
      const result = this.metrics.goalForGCI / commissionPerTransaction;
      return result.toFixed(2);
    },
    dealsNeededInPipeline() {
      const transactionsNeeded = parseFloat(this.formattedTransactionsNeeded);
      return Math.ceil(transactionsNeeded / 0.245);
    }
  },
  methods: {
    fetchGoals() {
      axios.get('/api/fetch_user_goals.php')
        .then(response => {
            if (response.data) {
                this.metrics.averageSalesPrice = response.data.average_sales_price || 0;
                this.metrics.averageCommissionRate = response.data.average_commission_rate || 0;
                this.metrics.goalForGCI = response.data.goal_for_GCI || 0;
            }
        })
        .catch(error => {
            console.error("Error fetching goals:", error);
        });
    },
    updateGoals() {
      axios.post('/api/update_user_goals.php', this.metrics)
        .then(response => {
          if (response.data.status === 'success') {
            alert("Goals updated successfully!");
          }
        })
        .catch(error => {
          console.error("Error updating goals:", error);
        });
    }
  },
  mounted() {
    this.fetchGoals();
  }
};
</script>

<style scoped>
.goals-panel {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin: 10px;
}
.form-group {
  margin-bottom: 10px;
}
.form-group label {
  display: block;
}
.form-group input[type="number"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}
.results {
  margin-top: 20px;
}
</style>
