<template>
  <div class="user-management-dashboard">
    <UserManagement @userChanged="handleUserChanged" />
    <DashBoard />
  </div>
</template>

<script>
import UserManagement from '@/components/UserManagement.vue';
import DashBoard from '@/components/DashBoard.vue';

export default {
  components: {
    UserManagement,
    DashBoard
  },
  methods: {
    handleUserChanged() {
      // Handle user change logic
    }
  }
};
</script>

<style scoped>
.user-management-dashboard {
  display: flex;
  flex-direction: column;
}
</style>
