// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import store from './store'; // Ensure you import the store correctly
import router from './router'; // Import the router

const app = createApp(App);
app.use(store); // Use the Vuex store
app.use(router); // Use the Vue Router
app.mount('#app');
