<template>
  <div class="daily-activities">
    <h2>Daily Activities and Results</h2>
    <form @submit.prevent="addActivity">
      <div class="form-group">
        <label for="date">Date:</label>
        <input type="date" id="date" v-model="newActivity.date" required>
      </div>
      <div class="form-group">
        <label for="hoursLeadGenerated">Hours Lead Generated:</label>
        <input type="number" id="hoursLeadGenerated" v-model="newActivity.hours_lead_generated" placeholder="Hours" step="0.01">
      </div>
      <div class="form-group">
        <label for="intentionalConversations">Intentional Conversations Had:</label>
        <input type="number" id="intentionalConversations" v-model="newActivity.intentional_conversations" placeholder="Conversations">
      </div>
      <div class="form-group">
        <label for="viableOpportunities">Viable Opportunities Added to Pipeline:</label>
        <input type="number" id="viableOpportunities" v-model="newActivity.viable_opportunities" placeholder="Opportunities">
      </div>
      <div class="form-group">
        <label for="apptsPresented">Appts: Agency Contracts Presented:</label>
        <input type="number" id="apptsPresented" v-model="newActivity.appts_presented" placeholder="Appointments Presented">
      </div>
      <div class="form-group">
        <label for="contractsSigned">Agency Contracts Signed:</label>
        <input type="number" id="contractsSigned" v-model="newActivity.contracts_signed" placeholder="Contracts Signed">
      </div>
      <div class="form-group">
        <label for="propertiesUnderContract">Properties Put Under Contract:</label>
        <input type="number" id="propertiesUnderContract" v-model="newActivity.properties_under_contract" placeholder="Properties Under Contract">
      </div>
      <div class="form-group">
        <label for="closingsHad">Closings Had:</label>
        <input type="number" id="closingsHad" v-model="newActivity.closings_had" placeholder="Closings">
      </div>
      <div class="form-group">
        <label for="gciEarned">GCI Earned:</label>
        <input type="number" id="gciEarned" v-model="newActivity.gci_earned" placeholder="$ GCI Earned" step="0.01">
      </div>
      <button type="submit">Add New Activity</button>
    </form>

    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Hours Lead Generated</th>
          <th>Intentional Conversations</th>
          <th>Viable Opportunities</th>
          <th>Appts Presented</th>
          <th>Contracts Signed</th>
          <th>Properties Under Contract</th>
          <th>Closings Had</th>
          <th>GCI Earned</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="activity in activities" :key="activity.id">
          <td>{{ activity.date }}</td>
          <td>{{ activity.hours_lead_generated }}</td>
          <td>{{ activity.intentional_conversations }}</td>
          <td>{{ activity.viable_opportunities }}</td>
          <td>{{ activity.appts_agency_contracts_presented }}</td>
          <td>{{ activity.agency_contracts_signed }}</td>
          <td>{{ activity.properties_under_contract }}</td>
          <td>{{ activity.closings_had }}</td>
          <td>{{ activity.gci_earned }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      activities: [],
      newActivity: {
        date: '',
        hours_lead_generated: 0,
        intentional_conversations: 0,
        viable_opportunities: 0,
        appts_presented: 0,
        contracts_signed: 0,
        properties_under_contract: 0,
        closings_had: 0,
        gci_earned: 0
      }
    };
  },
  methods: {
    ...mapActions(['fetchConversionRates']),
    fetchActivities() {
      axios.get('/api/fetch_daily_activities.php')
        .then(response => {
          this.activities = response.data;
        })
        .catch(error => console.error("Error fetching activities:", error));
    },
    addActivity() {
      axios.post('/api/add_daily_activity.php', this.newActivity)
        .then(response => {
          if (response.data.status === 'success' || response.data.status === 'updated') {
            this.fetchActivities();
            this.resetActivityForm();
            this.fetchConversionRates();
          }
        })
        .catch(error => console.error("Error adding activity:", error));
    },
    resetActivityForm() {
      this.newActivity = {
        date: '',
        hours_lead_generated: 0,
        intentional_conversations: 0,
        viable_opportunities: 0,
        appts_presented: 0,
        contracts_signed: 0,
        properties_under_contract: 0,
        closings_had: 0,
        gci_earned: 0
      };
    }
  },
  mounted() {
    this.fetchActivities();
  }
};
</script>

<style scoped>
.daily-activities {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

form {
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="number"], input[type="date"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #007BFF;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

th {
  background-color: #f8f9fa;
  color: #212529;
}

@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  input[type="number"], input[type="date"], button {
    width: 100%;
  }
}
</style>
