<template>
  <div id="app">
    <div v-if="!isAuthenticated">
      <router-view @authenticated="handleAuthentication" />
    </div>
    <div v-else class="content">
      <img alt="logo" src="@/assets/logo.png" class="centered-logo">
      <nav>
        <router-link to="/goals">Goals</router-link>
        <router-link to="/management-dashboard">Management Dashboard</router-link>
        <router-link to="/activities-conversion">Activities & Conversion Rates</router-link>
        <router-link to="/contacts">Contacts</router-link>
        <router-link to="/pipeline">Pipeline Entry</router-link>
        <button @click="logout">Logout</button>
      </nav>
      <router-view />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      isAuthenticated: false, // Controls whether user is considered logged in
    };
  },
  methods: {
    handleAuthentication(status) {
      this.isAuthenticated = status; // Updates `isAuthenticated` based on login status
      localStorage.setItem('isAuthenticated', status.toString()); // Store authentication status locally
      if (status) {
        this.$router.push({ path: '/goals' }); // Redirect to goals page after login
      }
    },
    checkLogin() {
      // Check if user is authenticated and refresh state accordingly
      if (localStorage.getItem('isAuthenticated') === 'true') {
        axios.get('/api/check_session.php')
          .then(response => {
            this.isAuthenticated = response.data.isAuthenticated;
            if (!this.isAuthenticated) {
              localStorage.removeItem('isAuthenticated'); // Clear local storage if session is no longer valid
              this.$router.push({ path: '/login' });
            }
          })
          .catch(() => {
            this.isAuthenticated = false;
            localStorage.removeItem('isAuthenticated'); // Clear local storage on error
            this.$router.push({ path: '/login' });
          });
      }
    },
    logout() {
      axios.post('/api/logout.php')
        .then(response => {
          if (response.data.status === 'success') {
            this.isAuthenticated = false;
            localStorage.removeItem('isAuthenticated');
            this.$router.push({ path: '/login' }); // Redirect to login page
          }
        })
        .catch(error => {
          console.error('Error logging out:', error);
        });
    }
  },
  created() {
    this.checkLogin(); // Check authentication status on component creation
  }
};
</script>

<style>
#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern and clean font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333; /* Darker text for better readability */
  margin-top: 20px;
}

.content {
  max-width: 100%; /* Full width to utilize space */
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff; /* White background for the content area */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding around the content */
}

.centered-logo {
  width: 150px; /* Define a fixed width for the logo */
  margin-bottom: 20px;
}

nav {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #004494; /* Darker blue on hover for visual feedback */
}

/* Responsive design using media queries */
[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}
}

@media only screen and (min-width: 768px) {
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}

@media only screen and (min-width: 992px) {
  /* Custom styles for large screens if needed */
}

@media only screen and (min-width: 1200px) {
  /* Custom styles for extra large screens if needed */
}
</style>
