<template>
  <div class="dashboard">
    <h2>Dashboard</h2>
    <div class="section goals">
      <h3>Agent Goals:</h3>
      <p><strong>Name:</strong> {{ agentName }}</p>
      <p><strong>Start Date:</strong> {{ agentStartDate }}</p>
      <p><strong>Days in Program:</strong> {{ daysInProgram }}</p>
      <p><strong>Conversations Had:</strong> {{ totalConversations }}</p>
      <p><strong>Deals in Pipeline:</strong> {{ dealsInPipeline }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, computed, onMounted } from 'vue';

export default {
  setup() {
    const userDetails = ref(null);

    const fetchUserData = () => {
      axios.get('/api/get_user_details.php')
        .then(response => {
          if (response.data.status === 'success') {
            userDetails.value = response.data.userDetails;
            fetchActivitiesData();
          } else {
            console.error('Error fetching user details:', response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching user details:', error);
        });
    };

    const fetchActivitiesData = () => {
      axios.get('/api/fetch_daily_activities.php')
        .then(response => {
          userDetails.value.totalConversations = response.data.reduce((acc, activity) => acc + activity.intentional_conversations, 0);
          userDetails.value.dealsInPipeline = response.data.reduce((acc, activity) => acc + activity.viable_opportunities, 0);
        })
        .catch(error => {
          console.error('Error fetching activities:', error);
        });
    };

    const daysInProgram = computed(() => {
      if (!userDetails.value || !userDetails.value.start_date) return 0;
      const start = new Date(userDetails.value.start_date);
      const today = new Date();
      return Math.floor((today - start) / (1000 * 60 * 60 * 24));
    });

    onMounted(fetchUserData);

    return {
      agentName: computed(() => userDetails.value ? `${userDetails.value.first_name} ${userDetails.value.last_name}` : ''),
      agentStartDate: computed(() => userDetails.value ? userDetails.value.start_date : ''),
      daysInProgram,
      totalConversations: computed(() => userDetails.value ? userDetails.value.totalConversations : 0),
      dealsInPipeline: computed(() => userDetails.value ? userDetails.value.dealsInPipeline : 0)
    };
  }
};
</script>

<style scoped>
.dashboard {
  width: 100%; /* Full width to fill out the container */
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

h2 {
  color: #0056b3; /* Stronger color for main title */
  font-size: 1.5rem; /* Larger font size for main title */
  margin-bottom: 20px;
}

.section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%; /* Ensure section takes full width */
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.section h3 {
  color: #0056b3;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.section p {
  margin: 10px 0;
  font-size: 1.1rem; /* Slightly larger font size for better readability */
  color: #666;
  line-height: 1.6;
}

strong {
  color: #333;
  font-weight: normal;
}
</style>
