<template>
  <div class="login-container">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="email" required>
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" v-model="password" required>
      </div>
      <button type="submit">Login</button>
    </form>
    <p v-if="loginError">{{ loginError }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoginContainer',
  data() {
    return {
      email: '',
      password: '',
      loginError: '',
    };
  },
  methods: {
  login() {
    axios.post('/api/login.php', { email: this.email, password: this.password })
      .then(response => {
        const { success, message, user_id } = response.data;
        if (success) {
          localStorage.setItem('user_id', user_id); // Store user ID on successful login
          this.$emit('authenticated', true); // Notify parent component
        } else {
          this.loginError = message;
        }
      })
      .catch(error => {
        console.error("Login error:", error);
        this.loginError = "Error logging in. Please try again.";
      });
  },

  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #333;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border 0.3s;
}

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #0056b3;
  outline: none;
}

button {
  width: 100%;
  padding: 12px 0;
  background-color: #0056b3;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #004494;
}

p {
  color: #cc0000; /* More noticeable error message */
  margin-top: 10px;
}
</style>
