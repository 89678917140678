<template>
  <div class="contacts-list">
    <button @click="addContact">Add New Contact</button>
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Origin</th>
          <th>Contact Method</th>
          <th>Problem/Note</th>
          <th>Solved?</th>
          <th>Timeline Secured</th>
          <th>Entered in Pipeline</th>
          <th>Notes</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contact in contacts" :key="contact.id">
          <td><input type="date" v-model="contact.date"></td>
          <td><input type="text" v-model="contact.name"></td>
          <td>
            <select v-model="contact.origin">
              <option value="incoming_contact">Incoming Contact</option>
              <option value="lead_follow_up">Lead Follow Up</option>
              <option value="vendor_partner_conversation">Vendor Partner Conversation</option>
            </select>
          </td>
          <td>
            <select v-model="contact.method">
              <option value="text">Text</option>
              <option value="phone">Phone</option>
              <option value="email">Email</option>
              <option value="in_person">In Person</option>
              <option value="dm">DM</option>
            </select>
          </td>
          <td><textarea v-model="contact.problem"></textarea></td>
          <td><input type="checkbox" v-model="contact.solved"></td>
          <td>
            <select v-model="contact.timeline">
              <option value="secured">Secured</option>
              <option value="not_secured">Not Secured</option>
              <option value="na">N/A</option>
              <option value="reassessed">Reassessed</option>
            </select>
          </td>
          <td>
            <select v-model="contact.pipeline">
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="na">N/A</option>
            </select>
          </td>
          <td><textarea v-model="contact.notes"></textarea></td>
          <td>
            <button @click="saveContact(contact)">Save</button>
            <button @click="deleteContact(contact.id)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      contacts: [],
    };
  },
  methods: {
    addContact() {
      const newContact = {
        date: new Date().toISOString().substr(0, 10),
        name: '',
        origin: 'incoming_contact',
        method: 'email',
        problem: '',
        solved: false,
        timeline: 'na',
        pipeline: 'na',
        notes: ''
      };
      this.contacts.push(newContact); // Add new contact locally
    },
    saveContact(contact) {
    // Ensure 'solved' is properly set to 0 or 1
    const payload = {
      ...contact,
      solved: contact.solved ? 1 : 0  // Convert boolean to 0 or 1
    };

    axios.post('/api/add_contact.php', payload)
      .then(response => {
        if (response.data.status === 'success') {
        alert('Contact saved successfully');
          this.fetchContacts(); // Refresh the list after save
        } else {
          alert(`Failed to save contact: ${response.data.message}`);
        }
      })
      .catch(error => {
        console.error('Error saving contact:', error);
        alert('Error saving contact: ' + error.message);
      });
  },

  deleteContact(id) {
  const params = new URLSearchParams();
  params.append('id', id);

  axios.post('/api/delete_contact.php', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
  .then(response => {
    if (response.data.status === 'success') {
      this.contacts = this.contacts.filter(contact => contact.id !== id); // Update UI
      alert('Contact deleted successfully');
    } else {
      alert('Failed to delete contact: ' + response.data.message);
    }
  })
  .catch(error => {
    console.error('Error deleting contact:', error);
    alert('Error deleting contact');
  });
}
,
    fetchContacts() {
      axios.get('/api/fetch_contacts.php')
        .then(response => {
          this.contacts = response.data.contacts;
        })
        .catch(error => {
          console.error('Error fetching contacts:', error);
        });
    }
  },
  mounted() {
    this.fetchContacts(); // Load contacts on component mount
  }
};
</script>

<style scoped>
.contacts-list {
  max-width: 1200px; /* Limit the width for better table readability */
  margin: 20px auto;
  padding: 20px;
  background-color: #fff; /* Bright background for contrast */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #004494;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  color: #333;
}

input[type="text"], input[type="email"], input[type="date"], select, textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

textarea {
  height: 60px; /* Sufficient height for notes */
}

input[type="checkbox"] {
  margin-top: 12px; /* Aligns better with the label */
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .contacts-list {
    padding: 10px;
  }

  button {
    padding: 8px 10px;
    font-size: 0.8rem;
  }

  input, select, textarea {
    padding: 8px;
    font-size: 0.9rem;
  }
}
</style>
