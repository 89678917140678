<template>
  <div class="pipeline-entry">
    <h2>Pipeline Entry</h2>
    <form @submit.prevent="addPipelineEntry">
      <div class="form-group">
        <label for="date">Date:</label>
        <input type="date" id="date" v-model="newEntry.date" required>
      </div>
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="newEntry.name" required>
      </div>
      <div class="form-group">
        <label for="address">Property Address:</label>
        <input type="text" id="address" v-model="newEntry.address">
      </div>
      <div class="form-group">
        <label for="timeline">Consumer Stated Timeline:</label>
        <input type="text" id="timeline" v-model="newEntry.timeline">
      </div>
      <div class="form-group">
        <label for="move_trigger">Trigger for Move:</label>
        <input type="text" id="move_trigger" v-model="newEntry.move_trigger">
      </div>
      <div class="form-group">
        <label for="details">Details:</label>
        <textarea id="details" v-model="newEntry.details"></textarea>
      </div>
      <div class="form-group">
        <label for="price">Expected Sales Price:</label>
        <input type="number" id="price" v-model="newEntry.price">
      </div>
      <div class="form-group">
        <label for="commission_rate">Commission Rate (%):</label>
        <input type="number" id="commission_rate" v-model="newEntry.commission_rate" step="0.01">
      </div>
      <div class="form-group">
        <label for="commission">Expected Commission:</label>
        <input type="number" id="commission" v-model="newEntry.commission" step="0.01">
      </div>
      <div class="form-group">
        <label for="source">Source:</label>
        <select id="source" v-model="newEntry.source">
          <option value="organic intentional conversation">Organic Intentional Conversation</option>
          <option value="incoming lead">Incoming Lead</option>
        </select>
      </div>
      <div class="form-group">
        <label for="deal_type">Type of Deal:</label>
        <select id="deal_type" v-model="newEntry.deal_type">
          <option value="buy side">Buy Side</option>
          <option value="list side">List Side</option>
          <option value="tenant rep">Tenant Rep</option>
          <option value="rental listing">Rental Listing</option>
          <option value="referral">Referral</option>
          <option value="both sides">Both Sides</option>
        </select>
      </div>
      <div class="form-group">
        <label for="pipeline_stage">Pipeline Stage:</label>
        <select id="pipeline_stage" v-model="newEntry.pipeline_stage">
          <option value="prospect">Prospect</option>
          <option value="contact">Contact</option>
          <option value="opportunity">Opportunity</option>
          <option value="active client">Active Client</option>
          <option value="under contract">Under Contract</option>
          <option value="closed">Closed</option>
          <option value="lost">Lost</option>
        </select>
      </div>
      <button type="submit">Add Entry</button>
    </form>

    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Property Address</th>
          <th>Timeline</th>
          <th>Trigger</th>
          <th>Details</th>
          <th>Price</th>
          <th>Commission Rate</th>
          <th>Commission</th>
          <th>Source</th>
          <th>Deal Type</th>
          <th>Pipeline Stage</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in entries" :key="entry.id">
          <td>{{ entry.date }}</td>
          <td>{{ entry.name }}</td>
          <td>{{ entry.address }}</td>
          <td>{{ entry.timeline }}</td>
          <td>{{ entry.move_trigger }}</td>
          <td>{{ entry.details }}</td>
          <td>{{ entry.price }}</td>
          <td>{{ entry.commission_rate }}</td>
          <td>{{ entry.commission }}</td>
          <td>{{ entry.source }}</td>
          <td>{{ entry.deal_type }}</td>
          <td>{{ entry.pipeline_stage }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      entries: [],
      newEntry: {
        date: '',
        name: '',
        address: '',
        timeline: '',
        move_trigger: '',
        details: '',
        price: 0,
        commission_rate: 0,
        commission: 0,
        source: 'organic intentional conversation',
        deal_type: 'buy side',
        pipeline_stage: 'prospect'
      }
    };
  },
  methods: {
    fetchEntries() {
      axios.get('/api/fetch_pipeline_entries.php')
        .then(response => {
          this.entries = response.data;
        })
        .catch(error => console.error("Error fetching entries:", error));
    },
    validateAndFormatDate(date) {
      if (!date) return null;
      // Check if the date is in the correct format YYYY-MM-DD
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) {
        return null; // Invalid date
      }
      // Format the date to YYYY-MM-DD
      return parsedDate.toISOString().split('T')[0];
    },
    addPipelineEntry() {
      // Validate and format dates
      const formattedDate = this.validateAndFormatDate(this.newEntry.date);
      if (!formattedDate) {
        alert('Please enter a valid date in the format YYYY-MM-DD');
        return;
      }

      // Update the entry with formatted dates, removing closing_date
      const entry = {
        ...this.newEntry,
        date: formattedDate
      };

      console.log('Formatted Entry:', entry); // Log the entry before sending

      axios.post('/api/add_pipeline_entry.php', entry)
        .then(response => {
          if (response.data.status === 'success') {
            this.fetchEntries();
            this.resetEntryForm();
          } else {
            console.error("Error adding entry:", response.data);
          }
        })
        .catch(error => console.error("Error adding entry:", error));
    },
    resetEntryForm() {
      this.newEntry = {
        date: '',
        name: '',
        address: '',
        timeline: '',
        move_trigger: '',
        details: '',
        price: 0,
        commission_rate: 0,
        commission: 0,
        source: 'organic intentional conversation',
        deal_type: 'buy side',
        pipeline_stage: 'prospect'
      };
    }
  },
  mounted() {
    this.fetchEntries();
  }
};
</script>

<style scoped>
.pipeline-entry {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form {
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="date"],
textarea,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #007BFF;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

th {
  background-color: #f8f9fa;
  color: #212529;
}

@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  input[type="number"],
  input[type="text"],
  input[type="date"],
  textarea,
  select,
  button {
    width: 100%;
  }
}
</style>
