<template>
  <div class="daily-activities-conversion-rates">
    <DailyActivities />
    <ConversionRates />
  </div>
</template>

<script>
import DailyActivities from '@/components/DailyActivities.vue';
import ConversionRates from '@/components/ConversionRates.vue';

export default {
  components: {
    DailyActivities,
    ConversionRates
  }
};
</script>

<style scoped>
.daily-activities-conversion-rates {
  display: flex;
  flex-direction: column;
}
</style>
