import { createRouter, createWebHistory } from 'vue-router';
import GoalsPanel from '@/components/GoalsPanel.vue';
import UserManagementDashboard from '@/components/UserManagementDashboard.vue';
import DailyActivitiesConversionRates from '@/components/DailyActivitiesConversionRates.vue';
import ContactsList from '@/components/ContactsList.vue';
import PipelineEntry from '@/components/PipelineEntry.vue';
import LoginContainer from '@/components/LoginContainer.vue';

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: LoginContainer },
  { path: '/goals', component: GoalsPanel, meta: { requiresAuth: true } },
  { path: '/management-dashboard', component: UserManagementDashboard, meta: { requiresAuth: true } },
  { path: '/activities-conversion', component: DailyActivitiesConversionRates, meta: { requiresAuth: true } },
  { path: '/contacts', component: ContactsList, meta: { requiresAuth: true } },
  { path: '/pipeline', component: PipelineEntry, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to check for authentication
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router;
